<template>
  <div>
    <b-card no-body>
      <div class="m-2">
        <div class="form-row justify-content-between">
          <div class="col-md-2">
            <v-select
              label="title"
              required
              v-model="filters.perPage"
              :options="perPageOptions"
              @input="setPerPageSelected"
              :clearable="false"
            />
          </div>
          <div class="col-md-8">
            <div class="form-row justify-content-end">
              <div class="col-md-6">
                <input
                  type="search"
                  class="form-control"
                  placeholder="Nome"
                  v-model="filters.title"
                />
              </div>
              <div class="col-md-1">
                <button
                  @click="getData()"
                  style="padding: 14px !important"
                  type="button"
                  class="btn btn-secondary btn-block"
                >
                  <i class="bi bi-search"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <b-table
        sticky-header
        class="position-relative"
        :items="dataItems"
        responsive
        :fields="tableColumns"
        primary-key="id"
        show-empty
        empty-filtered-text="Nenhum registro encontrado"
        empty-text="Nenhum registro encontrado"
        :filter="filter"
        :filter-included-fields="filterOn"
        @filtered="onFiltered"
      >
        <template #cell(full_name)="data">
          <b-media vertical-align="center">
            <template #aside>
              <b-avatar
                v-if="data.item.image"
                rounded="sm"
                size="32"
                :src="data.item.image"
                :text="avatarText(data.item.full_name)"
                variant="light-success"
              />
            </template>
            <span class="font-weight-bold d-block text-nowrap">
              {{ data.item.full_name }}
            </span>
            <small class="text-muted">{{ data.item.title }}</small>
          </b-media>
        </template>
        <template #cell(created_at)="data">
          {{ data.item.created_at | datePT(true) }}
        </template>
        <template #cell(actions)="data">
          <div class="form-row align-items-center justify-content-center">
            <div class="col-md-6 text-center">
              <feather-icon
                :id="`customer-${data.item.uuid}-delete-icon`"
                icon="TrashIcon"
                class="cursor-pointer cursor text-danger"
                size="16"
                @click="confirmDelete(data.item.uuid)"
              />
            </div>
            <div class="col-md-6 text-center">
              <feather-icon
                :id="`customer-${data.item.uuid}-edit-icon`"
                icon="EditIcon"
                class="cursor-pointer cursor"
                size="16"
                @click="modalEdit(data.item.uuid)"
              />
            </div>
          </div>
        </template>
      </b-table>

      <CustomPaginateTable
        :rows-table="rowsTable"
        :current-page="filters.currentPage"
        :per-page="filters.perPage"
        :total-rows="totalRows"
      />

      <b-modal
        id="modal-create-approved"
        modal-class="modal-create"
        size="lg"
        :title="titleModal"
        centered
        hide-footer
      >
        <b-form @submit.prevent="submitCreate" enctype="multipart/form-data">
          <b-row class="form-row">
            <b-col md="4">
              <input
                id="image"
                type="file"
                accept="image/*"
                class="form-control-file hidden"
                @change="previewImage"
              />
              <b-form-group>
                <label
                  for="image"
                  class="d-block text-center mb-0"
                  style="cursor: pointer; font-size: 1.1rem"
                >
                  <div
                    v-if="!preview && !item.image"
                    style="padding: 67px 10px !important"
                  >
                    <feather-icon size="19" icon="ImageIcon" />
                  </div>
                  <template v-if="preview">
                    <b-img
                      for="image"
                      :src="preview"
                      style="width: 100%; height: 200px"
                      fluid
                    />
                  </template>
                  <template v-else-if="!preview && item.image">
                    <b-img
                      for="image"
                      :src="item.image"
                      style="width: 100%; height: 200px"
                      fluid
                    />
                  </template>
                </label>
              </b-form-group>
            </b-col>

            <div class="col-md-8 col-12">
              <b-row class="form-row">
                <b-col cols="12" md="12">
                  <label for="">
                    <i class="text-danger bi bi-record-circle"></i>
                    Título
                  </label>
                  <div class="form-group">
                    <b-form-input
                      v-model="item.title"
                      :class="{ 'is-invalid': $v.item.title.$error }"
                    />
                  </div>
                </b-col>
                <b-col cols="12" md="6">
                  <label for="">
                    <i class="text-danger bi bi-record-circle"></i>
                    Nome
                  </label>
                  <div class="form-group">
                    <b-form-input
                      v-model="item.full_name"
                      :class="{ 'is-invalid': $v.item.full_name.$error }"
                    />
                  </div>
                </b-col>
                <b-col cols="12" md="6">
                  <label for=""> Ranking </label>
                  <div class="form-group">
                    <b-form-input
                      v-model="item.ranking"
                      placeholder="Ex.: 1º lugar no INSS"
                    />
                  </div>
                </b-col>
              </b-row>
            </div>
            <b-col cols="12">
              <b-form-group>
                <label for="">
                  <i class="text-danger bi bi-record-circle"></i>
                  Depoimento
                </label>
                <textarea
                  :class="{ 'is-invalid': $v.item.description.$error }"
                  class="form-control"
                  v-model="item.description"
                  cols="30"
                  rows="5"
                ></textarea>
              </b-form-group>
            </b-col>
            <b-col md="12">
              <b-form-group>
                <label for=""> Código do vídeo no Youtube</label>
                <b-input-group
                  prepend="https://www.youtube.com/watch?v="
                  class="input-group-merge"
                >
                  <b-form-input v-model="item.video_url" />
                </b-input-group>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group>
                <label for=""> Cargo </label>
                <v-select
                  ref="selectOffice"
                  label="title"
                  item-text="title"
                  item-value="code"
                  v-model="item.office"
                  placeholder="Digite o título"
                  :options="optionsOffices"
                  @search="fetchOffice"
                  :class="{ 'is-invalid': $v.item.office.$error }"
                >
                  <span
                    slot="no-options"
                    @click="$refs.selectOffice.open = false"
                  >
                    Nenhum registro encontrado
                  </span>
                </v-select>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group>
                <label for=""> Órgão </label>
                <v-select
                  ref="selectOrgan"
                  label="title"
                  item-text="title"
                  item-value="code"
                  v-model="item.organ"
                  placeholder="Digite o título"
                  :options="optionsOrgans"
                  @search="fetchOrgans"
                  :class="{ 'is-invalid': $v.item.organ.$error }"
                >
                  <span
                    slot="no-options"
                    @click="$refs.selectOrgan.open = false"
                  >
                    Nenhum registro encontrado
                  </span>
                </v-select>
              </b-form-group>
            </b-col>
            <b-col md="12">
              <b-form-group class="mb-0 mt-2">
                <b-form-checkbox
                  v-model="item.home"
                  class="custom-control-primary"
                >
                  Exibir na pág. inicial
                </b-form-checkbox>
              </b-form-group>
            </b-col>
          </b-row>
          <div class="form-row justify-content-end modal-footer-custom">
            <div class="col-md-7 col-12">
              <button
                type="button"
                @click="$bvModal.hide('modal-create-approved')"
                class="btn btn-light float-left"
              >
                Fechar
              </button>
              <ButtonsActionsFooter
                variant="success"
                :submited="submited"
                :text="textSubmit"
                subtext="Aguarde..."
              />
            </div>
          </div>
        </b-form>
      </b-modal>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BTable,
  BButton,
  BPagination,
  BMedia,
  BAvatar,
  BImg,
  BInputGroup,
  BModal,
  BFormGroup,
  BTooltip,
  BForm,
  BCardText,
  BFormRow,
  BContainer,
  BFormFile,
  BFormCheckbox,
} from "bootstrap-vue";
import { mapState } from "vuex";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import ButtonsActionsFooter from "@/views/components/button/ButtonsActionsFooter";
import { required, minLength } from "vuelidate/lib/validators";
import { avatarText } from "@core/utils/filter";
import CustomPaginateTable from "@/views/components/custom/PaginationTable.vue";

export default {
  setup() {
    return {
      avatarText,
    };
  },
  components: {
    BCard,
    BImg,
    BInputGroup,
    BFormCheckbox,
    BFormFile,
    BModal,
    BAvatar,
    BFormGroup,
    BRow,
    BCol,
    BFormInput,
    BTable,
    BButton,
    BPagination,
    vSelect,
    BTooltip,
    BForm,
    BCardText,
    BFormRow,
    BContainer,
    CustomPaginateTable,
    ButtonsActionsFooter,
    BMedia,
  },
  data() {
    return {
      preview: "",
      uuid: null,
      textSubmit: "",
      submited: false,
      filter: null,
      filterOn: [],
      currentPage: 1,
      perPage: 100,
      totalRows: 0,
      rowsTable: 0,
      perPageOptions: [
        {
          title: "100",
          value: 100,
        },
        {
          title: "200",
          value: 200,
        },
      ],
      tableColumns: [
        {
          key: "full_name",
          label: "Nome",
        },
        {
          key: "created_at",
          label: "Data",
          sortable: false,
          class: "text-center",
          thStyle: "width: 200px",
        },
        {
          key: "actions",
          label: "",
          thClass: "text-center td-actions",
          tdClass: "text-center",
          thStyle: "width: 80px",
        },
      ],
      dataItems: [],
      titleModal: "",
      optionsOrgans: [],
      optionsOffices: [],
      item: {
        full_name: "",
        ranking: "",
        title: "",
        image: "",
        created_at: "",
        home: 0,
        organ: "",
        office: "",
        video_url: "",
      },
      optionsStatus: [
        {
          title: "Ativo",
          code: 1,
        },
        {
          title: "Inativo",
          code: 0,
        },
      ],
      filters: {
        title: "",
        perPage: 100,
        currentPage: 1
      },
    };
  },
  validations: {
    item: {
      full_name: {
        required,
        minLength: minLength(5),
      },
      title: {
        required,
        minLength: minLength(5),
      },
      description: {
        required,
      },
      organ: {
        required,
      },
      office: {
        required,
      },
    },
  },
  mounted() {
    this.getData();
    this.$root.$on("open:modal-create-approved", () => {
      this.clearForm();
      this.textSubmit = "Cadastrar";
      this.titleModal = "Cadastrar Aluno Aprovado";
      this.$bvModal.show("modal-create-approved");
    });

    this.$root.$on("paginate:update", (res) => {
      this.filters.currentPage = res._currentPage;
      this.totalRows = res._totalRows;
      this.rowsTable = res._rowsTable;
      this.filters.perPage = res._perPage;
      this.getData();
    });
  },
  beforeDestroy() {
    this.$root.$off("open:modal-create-material");
    this.$root.$off("paginate:update");
  },
  methods: {
    async fetchOffice(term) {
      if (term.length > 2) {
        this.$store.dispatch("Office/search", term).then(() => {
          this.optionsOffices = this.searchOffices;
        });
      }
    },
    async fetchOrgans(term) {
      if (term.length > 1) {
        this.$store.dispatch("Organ/search", term).then(() => {
          this.optionsOrgans = this.searchOrgans;
        });
      }
    },
    previewImage(event) {
      const input = event.target;
      if (input.files) {
        const reader = new FileReader();
        reader.onload = (e) => {
          this.preview = e.target.result;
        };
        const file = input.files[0];
        this.item.image = file;
        reader.readAsDataURL(file);
      }
    },
    modalEdit(uuid) {
      this.textSubmit = "Salvar";
      this.titleModal = "Editar Aluno Aprovado";
      this.clearForm();

      this.$store.dispatch("Approved/show", uuid).then(() => {
        this.item = this.approved;
        this.item.home = this.item.home ? true : false;
      });

      this.uuid = uuid;
      this.$bvModal.show("modal-create-approved");
    },
    submitCreate() {
      this.$v.$touch();
      this.submited = true;

      const formData = new FormData();
      formData.append("image", this.item.image);
      formData.append("title", this.item.title);
      formData.append("full_name", this.item.full_name);
      formData.append("description", this.item.description);
      formData.append("ranking", this.item.ranking ?? "");
      formData.append("video_url", this.item.video_url ?? "");
      formData.append("organ", this.item.organ ? this.item.organ.code : "");
      formData.append("office", this.item.office ? this.item.office.code : "");
      formData.append("home", this.item.home ? 1 : 0);

      if (!this.$v.$error) {
        if (this.textSubmit === "Cadastrar") {
          this.$store
            .dispatch("Approved/create", formData)
            .then(() => {
              this.getData();
              this.notifyDefault("success");

              this.$nextTick(() => {
                this.$bvModal.hide("modal-create-approved");
                this.clearForm();
              });
            })
            .catch(() => {
              this.submited = false;
              this.notifyDefault("error");
            })
            .finally(() => {
              this.submited = false;
            });
        } else {
          this.$store
            .dispatch("Approved/update", {
              uuid: this.item.uuid,
              data: formData,
            })
            .then(() => {
              this.getData();
              this.notifyDefault("success");

              this.$nextTick(() => {
                this.$bvModal.hide("modal-create-approved");
                this.clearForm();
              });
            })
            .catch((err) => {
              this.submited = false;
              this.notifyDefault("error");
            })
            .finally(() => {
              this.submited = false;
            });
        }
      } else {
        this.submited = false;
        this.notifyDefault("error", "Verifique os campos obrigatórios");
      }
    },
    setPerPageSelected(obj) {
      this.perPage = obj.value;
      this.getData();
    },
    clearForm() {
      this.item = {
        full_name: "",
        title: "",
        ranking: "",
        image: "",
        created_at: "",
        video_url: "",
        home: 0,
        organ: "",
      };
    },
    async getData() {
      this.$store
        .dispatch("Approved/all", this.filters)
        .then((data) => {
          if (data) {
            this.dataItems = data.data;
            this.rowsTable = data.data.length;
            this.totalRows = data.meta.total;
            this.currentPage = data.meta.current_page;
          }
        });
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    updatePage() {
      this.getData();
    },
    confirmDelete(uuid) {
      this.$swal({
        title: "Quer excluir?",
        text: "Esta ação será irreversível!",
        icon: "error",
        showCancelButton: true,
        confirmButtonText: "Sim, quero excluir!",
        customClass: {
          confirmButton: "btn btn-danger",
          cancelButton: "btn btn-light ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.$store.dispatch("Approved/delete", uuid).then(() => {
            this.getData();
            this.$swal({
              icon: "success",
              title: "Deletado!",
              text: "Operação realizada com sucesso.",
              customClass: {
                confirmButton: "btn btn-success",
              },
            });
          });
        }
      });
    },
  },
  directives: {
    Ripple,
  },
  computed: {
    ...mapState("Approved", ["approveds", "approved"]),
    ...mapState("Organ", ["searchOrgans"]),
    ...mapState("Office", ["searchOffices"]),
  },
};
</script>
