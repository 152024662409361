var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-card",
        { attrs: { "no-body": "" } },
        [
          _c("div", { staticClass: "m-2" }, [
            _c("div", { staticClass: "form-row justify-content-between" }, [
              _c(
                "div",
                { staticClass: "col-md-2" },
                [
                  _c("v-select", {
                    attrs: {
                      label: "title",
                      required: "",
                      options: _vm.perPageOptions,
                      clearable: false,
                    },
                    on: { input: _vm.setPerPageSelected },
                    model: {
                      value: _vm.filters.perPage,
                      callback: function ($$v) {
                        _vm.$set(_vm.filters, "perPage", $$v)
                      },
                      expression: "filters.perPage",
                    },
                  }),
                ],
                1
              ),
              _c("div", { staticClass: "col-md-8" }, [
                _c("div", { staticClass: "form-row justify-content-end" }, [
                  _c("div", { staticClass: "col-md-6" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.filters.title,
                          expression: "filters.title",
                        },
                      ],
                      staticClass: "form-control",
                      attrs: { type: "search", placeholder: "Nome" },
                      domProps: { value: _vm.filters.title },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(_vm.filters, "title", $event.target.value)
                        },
                      },
                    }),
                  ]),
                  _c("div", { staticClass: "col-md-1" }, [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-secondary btn-block",
                        staticStyle: { padding: "14px !important" },
                        attrs: { type: "button" },
                        on: {
                          click: function ($event) {
                            return _vm.getData()
                          },
                        },
                      },
                      [_c("i", { staticClass: "bi bi-search" })]
                    ),
                  ]),
                ]),
              ]),
            ]),
          ]),
          _c("b-table", {
            staticClass: "position-relative",
            attrs: {
              "sticky-header": "",
              items: _vm.dataItems,
              responsive: "",
              fields: _vm.tableColumns,
              "primary-key": "id",
              "show-empty": "",
              "empty-filtered-text": "Nenhum registro encontrado",
              "empty-text": "Nenhum registro encontrado",
              filter: _vm.filter,
              "filter-included-fields": _vm.filterOn,
            },
            on: { filtered: _vm.onFiltered },
            scopedSlots: _vm._u([
              {
                key: "cell(full_name)",
                fn: function (data) {
                  return [
                    _c(
                      "b-media",
                      {
                        attrs: { "vertical-align": "center" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "aside",
                              fn: function () {
                                return [
                                  data.item.image
                                    ? _c("b-avatar", {
                                        attrs: {
                                          rounded: "sm",
                                          size: "32",
                                          src: data.item.image,
                                          text: _vm.avatarText(
                                            data.item.full_name
                                          ),
                                          variant: "light-success",
                                        },
                                      })
                                    : _vm._e(),
                                ]
                              },
                              proxy: true,
                            },
                          ],
                          null,
                          true
                        ),
                      },
                      [
                        _c(
                          "span",
                          {
                            staticClass: "font-weight-bold d-block text-nowrap",
                          },
                          [_vm._v(" " + _vm._s(data.item.full_name) + " ")]
                        ),
                        _c("small", { staticClass: "text-muted" }, [
                          _vm._v(_vm._s(data.item.title)),
                        ]),
                      ]
                    ),
                  ]
                },
              },
              {
                key: "cell(created_at)",
                fn: function (data) {
                  return [
                    _vm._v(
                      " " +
                        _vm._s(_vm._f("datePT")(data.item.created_at, true)) +
                        " "
                    ),
                  ]
                },
              },
              {
                key: "cell(actions)",
                fn: function (data) {
                  return [
                    _c(
                      "div",
                      {
                        staticClass:
                          "form-row align-items-center justify-content-center",
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "col-md-6 text-center" },
                          [
                            _c("feather-icon", {
                              staticClass: "cursor-pointer cursor text-danger",
                              attrs: {
                                id:
                                  "customer-" + data.item.uuid + "-delete-icon",
                                icon: "TrashIcon",
                                size: "16",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.confirmDelete(data.item.uuid)
                                },
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "col-md-6 text-center" },
                          [
                            _c("feather-icon", {
                              staticClass: "cursor-pointer cursor",
                              attrs: {
                                id: "customer-" + data.item.uuid + "-edit-icon",
                                icon: "EditIcon",
                                size: "16",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.modalEdit(data.item.uuid)
                                },
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("CustomPaginateTable", {
            attrs: {
              "rows-table": _vm.rowsTable,
              "current-page": _vm.filters.currentPage,
              "per-page": _vm.filters.perPage,
              "total-rows": _vm.totalRows,
            },
          }),
          _c(
            "b-modal",
            {
              attrs: {
                id: "modal-create-approved",
                "modal-class": "modal-create",
                size: "lg",
                title: _vm.titleModal,
                centered: "",
                "hide-footer": "",
              },
            },
            [
              _c(
                "b-form",
                {
                  attrs: { enctype: "multipart/form-data" },
                  on: {
                    submit: function ($event) {
                      $event.preventDefault()
                      return _vm.submitCreate.apply(null, arguments)
                    },
                  },
                },
                [
                  _c(
                    "b-row",
                    { staticClass: "form-row" },
                    [
                      _c(
                        "b-col",
                        { attrs: { md: "4" } },
                        [
                          _c("input", {
                            staticClass: "form-control-file hidden",
                            attrs: {
                              id: "image",
                              type: "file",
                              accept: "image/*",
                            },
                            on: { change: _vm.previewImage },
                          }),
                          _c("b-form-group", [
                            _c(
                              "label",
                              {
                                staticClass: "d-block text-center mb-0",
                                staticStyle: {
                                  cursor: "pointer",
                                  "font-size": "1.1rem",
                                },
                                attrs: { for: "image" },
                              },
                              [
                                !_vm.preview && !_vm.item.image
                                  ? _c(
                                      "div",
                                      {
                                        staticStyle: {
                                          padding: "67px 10px !important",
                                        },
                                      },
                                      [
                                        _c("feather-icon", {
                                          attrs: {
                                            size: "19",
                                            icon: "ImageIcon",
                                          },
                                        }),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm.preview
                                  ? [
                                      _c("b-img", {
                                        staticStyle: {
                                          width: "100%",
                                          height: "200px",
                                        },
                                        attrs: {
                                          for: "image",
                                          src: _vm.preview,
                                          fluid: "",
                                        },
                                      }),
                                    ]
                                  : !_vm.preview && _vm.item.image
                                  ? [
                                      _c("b-img", {
                                        staticStyle: {
                                          width: "100%",
                                          height: "200px",
                                        },
                                        attrs: {
                                          for: "image",
                                          src: _vm.item.image,
                                          fluid: "",
                                        },
                                      }),
                                    ]
                                  : _vm._e(),
                              ],
                              2
                            ),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "col-md-8 col-12" },
                        [
                          _c(
                            "b-row",
                            { staticClass: "form-row" },
                            [
                              _c("b-col", { attrs: { cols: "12", md: "12" } }, [
                                _c("label", { attrs: { for: "" } }, [
                                  _c("i", {
                                    staticClass:
                                      "text-danger bi bi-record-circle",
                                  }),
                                  _vm._v(" Título "),
                                ]),
                                _c(
                                  "div",
                                  { staticClass: "form-group" },
                                  [
                                    _c("b-form-input", {
                                      class: {
                                        "is-invalid": _vm.$v.item.title.$error,
                                      },
                                      model: {
                                        value: _vm.item.title,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.item, "title", $$v)
                                        },
                                        expression: "item.title",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ]),
                              _c("b-col", { attrs: { cols: "12", md: "6" } }, [
                                _c("label", { attrs: { for: "" } }, [
                                  _c("i", {
                                    staticClass:
                                      "text-danger bi bi-record-circle",
                                  }),
                                  _vm._v(" Nome "),
                                ]),
                                _c(
                                  "div",
                                  { staticClass: "form-group" },
                                  [
                                    _c("b-form-input", {
                                      class: {
                                        "is-invalid":
                                          _vm.$v.item.full_name.$error,
                                      },
                                      model: {
                                        value: _vm.item.full_name,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.item, "full_name", $$v)
                                        },
                                        expression: "item.full_name",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ]),
                              _c("b-col", { attrs: { cols: "12", md: "6" } }, [
                                _c("label", { attrs: { for: "" } }, [
                                  _vm._v(" Ranking "),
                                ]),
                                _c(
                                  "div",
                                  { staticClass: "form-group" },
                                  [
                                    _c("b-form-input", {
                                      attrs: {
                                        placeholder: "Ex.: 1º lugar no INSS",
                                      },
                                      model: {
                                        value: _vm.item.ranking,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.item, "ranking", $$v)
                                        },
                                        expression: "item.ranking",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "b-col",
                        { attrs: { cols: "12" } },
                        [
                          _c("b-form-group", [
                            _c("label", { attrs: { for: "" } }, [
                              _c("i", {
                                staticClass: "text-danger bi bi-record-circle",
                              }),
                              _vm._v(" Depoimento "),
                            ]),
                            _c("textarea", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.item.description,
                                  expression: "item.description",
                                },
                              ],
                              staticClass: "form-control",
                              class: {
                                "is-invalid": _vm.$v.item.description.$error,
                              },
                              attrs: { cols: "30", rows: "5" },
                              domProps: { value: _vm.item.description },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.item,
                                    "description",
                                    $event.target.value
                                  )
                                },
                              },
                            }),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "b-col",
                        { attrs: { md: "12" } },
                        [
                          _c(
                            "b-form-group",
                            [
                              _c("label", { attrs: { for: "" } }, [
                                _vm._v(" Código do vídeo no Youtube"),
                              ]),
                              _c(
                                "b-input-group",
                                {
                                  staticClass: "input-group-merge",
                                  attrs: {
                                    prepend: "https://www.youtube.com/watch?v=",
                                  },
                                },
                                [
                                  _c("b-form-input", {
                                    model: {
                                      value: _vm.item.video_url,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.item, "video_url", $$v)
                                      },
                                      expression: "item.video_url",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "b-col",
                        { attrs: { md: "6" } },
                        [
                          _c(
                            "b-form-group",
                            [
                              _c("label", { attrs: { for: "" } }, [
                                _vm._v(" Cargo "),
                              ]),
                              _c(
                                "v-select",
                                {
                                  ref: "selectOffice",
                                  class: {
                                    "is-invalid": _vm.$v.item.office.$error,
                                  },
                                  attrs: {
                                    label: "title",
                                    "item-text": "title",
                                    "item-value": "code",
                                    placeholder: "Digite o título",
                                    options: _vm.optionsOffices,
                                  },
                                  on: { search: _vm.fetchOffice },
                                  model: {
                                    value: _vm.item.office,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.item, "office", $$v)
                                    },
                                    expression: "item.office",
                                  },
                                },
                                [
                                  _c(
                                    "span",
                                    {
                                      attrs: { slot: "no-options" },
                                      on: {
                                        click: function ($event) {
                                          _vm.$refs.selectOffice.open = false
                                        },
                                      },
                                      slot: "no-options",
                                    },
                                    [_vm._v(" Nenhum registro encontrado ")]
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "b-col",
                        { attrs: { md: "6" } },
                        [
                          _c(
                            "b-form-group",
                            [
                              _c("label", { attrs: { for: "" } }, [
                                _vm._v(" Órgão "),
                              ]),
                              _c(
                                "v-select",
                                {
                                  ref: "selectOrgan",
                                  class: {
                                    "is-invalid": _vm.$v.item.organ.$error,
                                  },
                                  attrs: {
                                    label: "title",
                                    "item-text": "title",
                                    "item-value": "code",
                                    placeholder: "Digite o título",
                                    options: _vm.optionsOrgans,
                                  },
                                  on: { search: _vm.fetchOrgans },
                                  model: {
                                    value: _vm.item.organ,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.item, "organ", $$v)
                                    },
                                    expression: "item.organ",
                                  },
                                },
                                [
                                  _c(
                                    "span",
                                    {
                                      attrs: { slot: "no-options" },
                                      on: {
                                        click: function ($event) {
                                          _vm.$refs.selectOrgan.open = false
                                        },
                                      },
                                      slot: "no-options",
                                    },
                                    [_vm._v(" Nenhum registro encontrado ")]
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "b-col",
                        { attrs: { md: "12" } },
                        [
                          _c(
                            "b-form-group",
                            { staticClass: "mb-0 mt-2" },
                            [
                              _c(
                                "b-form-checkbox",
                                {
                                  staticClass: "custom-control-primary",
                                  model: {
                                    value: _vm.item.home,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.item, "home", $$v)
                                    },
                                    expression: "item.home",
                                  },
                                },
                                [_vm._v(" Exibir na pág. inicial ")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    {
                      staticClass:
                        "form-row justify-content-end modal-footer-custom",
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "col-md-7 col-12" },
                        [
                          _c(
                            "button",
                            {
                              staticClass: "btn btn-light float-left",
                              attrs: { type: "button" },
                              on: {
                                click: function ($event) {
                                  return _vm.$bvModal.hide(
                                    "modal-create-approved"
                                  )
                                },
                              },
                            },
                            [_vm._v(" Fechar ")]
                          ),
                          _c("ButtonsActionsFooter", {
                            attrs: {
                              variant: "success",
                              submited: _vm.submited,
                              text: _vm.textSubmit,
                              subtext: "Aguarde...",
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }